import { Button, Input, InputNumber, Modal, Table, Tabs } from 'antd';
import { useState } from 'react';

const BridgeWalletModal = ({ open, handleCancel, accountDetails }) => {
  const ethToMercataColumns = [
    {
      title: 'ETH Available',
      dataIndex: 'ethBalance',
      align: 'center',
    },
    {
      title: 'Set Quantity',
      align: 'center',
      render: () => <InputNumber />,
    },
    {
      title: 'Wallet Address',
      dataIndex: 'walletAddress',
      align: 'center',
      render: (_, record) => (
        <Input disabled={true} value={record.walletAddress} />
      ),
    },
  ];

  const ethToBaseColumns = [
    {
      title: 'ETHST Available',
      align: 'center',
    },
    {
      title: 'Set Quantity',
      align: 'center',
      render: () => <InputNumber />,
    },
    {
      title: 'Wallet Address',
      dataIndex: 'walletAddress',
      align: 'center',
      render: (_, record) => (
        <Input disabled={true} value={record.walletAddress} />
      ),
    },
  ];

  const ethToMercata = () => (
    <>
      <div className="head hidden md:block">
        <Table
          columns={ethToMercataColumns}
          dataSource={[accountDetails]}
          pagination={false}
        />
      </div>
      <div className="flex flex-col gap-[18px] md:hidden mt-5">
        <div>
          <p className="text-[#202020] font-medium text-sm">
            Quantity Available
          </p>
          <div className="border border-[#d9d9d9] h-[42px] rounded-md flex items-center justify-center">
            <p>10</p>
          </div>
        </div>
        <div>
          <p className="text-[#202020] font-medium text-sm">Set Quantity</p>
          <div>
            <InputNumber className="w-full h-9" />
          </div>
        </div>
        <div>
          <p className="text-[#202020] font-medium text-sm">
            Base Wallet Address
          </p>
          <Input placeholder="Base Chain address" />
        </div>
      </div>
    </>
  );

  const ethToBase = () => (
    <>
      <div className="head hidden md:block">
        <Table
          columns={ethToBaseColumns}
          dataSource={[accountDetails]}
          pagination={false}
        />
      </div>
      <div className="flex flex-col gap-[18px] md:hidden mt-5">
        <div>
          <p className="text-[#202020] font-medium text-sm">
            Quantity Available
          </p>
          <div className="border border-[#d9d9d9] h-[42px] rounded-md flex items-center justify-center">
            <p>10</p>
          </div>
        </div>
        <div>
          <p className="text-[#202020] font-medium text-sm">Set Quantity</p>
          <div>
            <InputNumber className="w-full h-9" />
          </div>
        </div>
        <div>
          <p className="text-[#202020] font-medium text-sm">
            Base Wallet Address
          </p>
          <Input placeholder="Base Chain address" />
        </div>
      </div>
    </>
  );

  const handleSubmit = async () => {
    console.log('body');
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      title={`Bridge Wallet`}
      width={1000}
      footer={[
        <div className="flex justify-center md:block">
          <Button type="primary" className="w-32 h-9" onClick={handleSubmit}>
            Bridge
          </Button>
        </div>,
      ]}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Bridge ETH to Mercata" key="1">
          {ethToMercata()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Bridge ETH to Base" key="2">
          {ethToBase()}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default BridgeWalletModal;
